import React from "react";
import { Flex, Box, Link, Image, Text } from "rebass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Wrapper, Constrain } from "components/Wrap";
import { useOrg } from "hooks/useUser";
import Adrien from "components/Adrien_Tahon_Serious.jpg";

const filterLinks = ({ title, url } = {}) => {
  if (!title || !url) return false;
  return true;
};

const OrgLink = ({ title, url, icon, description }) => (
  <Box
    as={Link}
    href={url}
    target="_blank"
    width="15em"
    m="1em"
    p="3"
    backgroundColor="white"
    sx={{
      textAlign: "center",
      justifyContent: "center",
      alignContent: "center",
      textDecoration: "none",
      borderRadius: "20px",
      boxShadow: "0px 2px 15px 3px rgb(0 0 0 / 15%)",
    }}
  >
    <Box mb="1em" p="3" color="secondary">
      <FontAwesomeIcon size="5x" icon={icon || "link"} />
    </Box>
    <Box pb="2" fontSize={3} color="highlight">
      {title}
    </Box>
    <Box pb="1" fontSize={2} color="darkBackground">
      {description}
    </Box>
  </Box>
);

export const OrgLinks = () => {
  const {
    org: { links },
    showLinks,
  } = useOrg();
  if (!showLinks) {
    return null;
  }

  const cleanLinks = links.list.filter(filterLinks);

  return (
    <Wrapper>
      <Constrain color="text">
        <Flex
          flexDirection="column"
          pb={4}
          sx={{
            borderBottom: "1px solid lightGrey",
          }}
        >
          <Text fontSize="5" fontWeight="bold" py="3">
            Bienvenue sur BlaBlaCar Daily Entreprises !
          </Text>
          <Flex alignItems="start">
            <Image
              flexShrink={0}
              flexBasis="124px"
              src={Adrien}
              alt="Adrien Tahon"
            />
            <Flex px={3} flexDirection="column">
              <Text fontSize="3" lineHeight="1.25" color="#555">
                Avant tout de chose, un grand merci pour l’intérêt que vous
                portez à BlaBlaCar Daily et au covoiturage domicile-travail.
                Participer au bien-être des salariés, augmenter leur pouvoir
                d’achat, libérer les parkings : le covoiturage domicile-travail
                avec ses collègues, c’est un geste pour le porte-monnaie mais
                aussi pour la planète ! Pour vous aider dans cette démarche
                éco-responsable, vous trouverez plusieurs documents pour
                présenter BlaBlaCar Daily à vos salariés, démocratiser le
                covoiturage et encourager son usage. A bientôt sur BlaBlaCar
                Daily !
              </Text>
              <Text textAlign="right" pt={3}>
                — Adrien Tahon, Directeur Général BlaBlaCar Daily
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex justifyContent="center" p="2em" flexWrap="wrap">
          {cleanLinks.map((link, i) => (
            <OrgLink {...link} key={i} />
          ))}
        </Flex>
      </Constrain>
    </Wrapper>
  );
};
