import React from "react";

import logo from "./logo.png";
import logoSmall from "./logo_small.png";
import logo2x from "./logo@2x.png";
import logoSmall2x from "./logo_small@2x.png";

export const Logo = ({ small }) => (
  <img
    alt="BlaBlaCar Daily Enterprises logo"
    src={small ? logoSmall : logo}
    srcSet={`${small ? logoSmall2x : logo2x} 2x`}
  />
);
