import React, { useState } from "react";
import { Flex, Box, Button, Link, Card, Text } from "rebass";
import { Label, Input } from "@rebass/forms";

import { useUser } from "hooks/useUser";
import { Logo } from "components/Logo";
import { GoogleLogo } from "components/GoogleLogo";

const LoginForm = ({ signIn, email: initEmail }) => {
  const [email, setEmail] = useState(initEmail);
  const [password, setPassword] = useState("");
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [hasReset, setHasReset] = useState(false);
  const { resetPassword } = useUser();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await signIn(email, password);
    } catch (e) {
      setError("Email ou mot de passe invalide");
      setLoading(false);
    }
  };

  const handleChange =
    (change) =>
    ({ target: { value } }) => {
      setError();
      change(value);
    };

  const reset = async () => {
    if (!email) {
      setError("Veuillez entrer votre email");
      return;
    }
    setError();
    await resetPassword(email);
    setHasReset(true);
  };

  return (
    <Box as="form" onSubmit={handleSubmit} pt={3}>
      <Box px={2} pb={3}>
        <Label htmlFor="email" sx={{ fontWeight: "bold" }}>
          Email
        </Label>
        <Input
          value={email}
          id="email"
          name="email"
          sx={{ borderColor: "lightGrey" }}
          onChange={handleChange(setEmail)}
          disabled={loading}
        />
      </Box>
      <Box px={2}>
        <Label htmlFor="password" sx={{ fontWeight: "bold" }}>
          Mot de passe
        </Label>
        <Input
          value={password}
          id="password"
          name="password"
          type="password"
          sx={{ borderColor: "lightGrey" }}
          onChange={handleChange(setPassword)}
          disabled={loading}
        />
      </Box>
      {error && (
        <Text textAlign="center" color="red" pt={3}>
          {error}
        </Text>
      )}
      <Flex justifyContent="center">
        <Flex pt={3} flexDirection="column">
          <Button disabled={loading} variant="secondary" mr={2} type="submit">
            Se connecter
          </Button>
          <Box mb={3} />
          {hasReset ? (
            <Text fontSize="2" py="1" color="primary">
              Si vous avez un compte, nous vous avons envoyé un email pour
              réinitialiser votre mot de passe.
            </Text>
          ) : (
            <Link
              onClick={reset}
              type="button"
              sx={{ cursor: "pointer" }}
              fontSize={1}
              textAlign="center"
              color="muted"
            >
              Réinitialiser votre mot de passe
            </Link>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export const Login = () => {
  const email = new URLSearchParams(window.location.search).get("email");
  const [showingForm, setShowForm] = useState(!!email);
  const { signIn, signInWithGoogle } = useUser();

  const showForm = () => setShowForm(true);
  const hideForm = () => setShowForm(false);

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      height="100%"
      bg="darkBackground"
    >
      <Card width={320} p={3} sx={{ borderRadius: 4 }} fontSize={2}>
        <Flex alignItems="center" pt={3} flexDirection="column">
          <Flex mb={2}>
            <Box>
              <Logo width={200} />
            </Box>
          </Flex>

          <Flex flexDirection="column" pt={3} sx={{ width: "100%" }}>
            {showingForm ? null : (
              <Button
                variant="outline"
                onClick={signInWithGoogle}
                sx={{ mb: 2 }}
              >
                <Flex justifyContent="center">
                  <GoogleLogo />
                  <Box ml={2}>Se connecter avec Google</Box>
                </Flex>
              </Button>
            )}

            {showingForm ? (
              <Flex flexDirection="column">
                <Link
                  onClick={hideForm}
                  sx={{ cursor: "pointer" }}
                  fontSize={1}
                  textAlign="left"
                  color="muted"
                >
                  ◀ Annuler
                </Link>
                <LoginForm email={email} signIn={signIn} />
              </Flex>
            ) : (
              <Button variant="outline" onClick={showForm}>
                Se connecter par email
              </Button>
            )}
          </Flex>
        </Flex>
      </Card>
    </Flex>
  );
};
