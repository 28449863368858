import React, { useState, useEffect, useContext, createContext } from "react";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

// https://console.firebase.google.com/u/0/project/blablalines-enterprises-test/database/blablalines-enterprises-test/data/~2Forgs~2F123~2Flinks
// const TEST_OPTS = {
//   apiKey: "AIzaSyDunRvWWXIc_JstqLsvkxDJkww4D-m1OgQ",
//   authDomain: "blablalines-enterprises-test.firebaseapp.com",
//   databaseURL: "https://blablalines-enterprises-test.firebaseio.com",
//   projectId: "blablalines-enterprises-test",
//   storageBucket: "blablalines-enterprises-test.appspot.com",
//   messagingSenderId: "951753467146",
//   appId: "1:951753467146:web:97b9f1a555be42e8ae86f3",
// };
const PROD_OPTS = {
  apiKey: "AIzaSyAw4tinjQfO-lzis0Tbh2zVIZKqhWDElMw",
  authDomain: "blablalines.firebaseapp.com",
  databaseURL: "https://blablalines.firebaseio.com",
  projectId: "blablalines",
  storageBucket: "blablalines.appspot.com",
  messagingSenderId: "583121057901",
  appId: "1:583121057901:web:60ea139294062496a76764",
  measurementId: "G-QV1H4K8LFN",
};

firebase.initializeApp(PROD_OPTS);

const UserContext = createContext();

const getOrg = async (uid) => {
  const orgId = (
    await firebase.database().ref(`users/${uid}`).once("value")
  ).val();
  return (await firebase.database().ref(`orgs/${orgId}`).once("value")).val();
};

export const UserProvider = ({ children }) => {
  const [error, setError] = useState();
  const [user, setUser] = useState();

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (change) => {
      if (!change) return setUser(null);
      if (change && user) return;

      try {
        const { email, uid } = change;
        const org = await getOrg(uid);
        setUser({
          org,
          email,
          uid,
        });
      } catch (e) {
        setError({ ...e, email: change.email });
      }
    });
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, error, setError }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const { user, setUser, error, setError } = useContext(UserContext);
  const loadingUser = typeof user === "undefined";
  const goog = new firebase.auth.GoogleAuthProvider();

  const signIn = async (email, password) => {
    try {
      // We don't need to use the response here because `onAuthStateChange` will capture it
      await firebase.auth().signInWithEmailAndPassword(email, password);
    } catch (e) {
      throw e.message;
    }
  };

  const resetPassword = async (email) =>
    firebase.auth().sendPasswordResetEmail(email);

  const signInWithGoogle = () => {
    firebase.auth().signInWithRedirect(goog);
  };

  const signOut = async () => {
    await firebase.auth().signOut();
    setUser(null);
    setError();
  };

  return {
    loadingUser,
    user,
    signIn,
    signInWithGoogle,
    signOut,
    resetPassword,
    error,
  };
};

export const useOrg = () => {
  const { user } = useUser();
  if (!user) return {};

  const {
    org: { links },
  } = user;

  const showLinks =
    links && links.list && links.enabled && links.list.length > 0;
  const showGeneralDocs = !links || !links.disableOldDocuments;

  return {
    org: user.org,
    showLinks,
    showGeneralDocs,
  };
};
