import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import { ThemeProvider } from "emotion-theming";
import preset from "@rebass/preset";
import { Box, Flex, Text, Link, Button } from "rebass";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import { UserProvider, useUser, useOrg } from "hooks/useUser";
import { NavBar } from "components/NavBar";
import { Logo } from "components/Logo";

import { Login } from "./Login";
import { Reports } from "./Reports";
import { Documents } from "./Documents";
import { OrgLinks } from "./OrgLinks";

// https://fontawesome.com/icons?d=gallery&s=solid&m=free
library.add(fas);

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

const Providers = ({ children }) => (
  <UserProvider>
    <ThemeProvider
      theme={{
        ...preset,
        colors: {
          ...preset.colors,
          text: "#111",
          background: "rgb(243,243,243)",
          darkBackground: "#30004b",
          darkerBackground: "#210033",
          primary: "#4285F4",
          secondary: "#00d8a7",
          highlight: "#f24495",
          muted: "#999",
        },

        buttons: {
          primary: {
            fontSize: 2,
            fontWeight: "bold",
            color: "background",
            bg: "primary",
            borderRadius: "default",
          },
          outline: {
            variant: "buttons.primary",
            color: "primary",
            bg: "transparent",
            boxShadow: "inset 0 0 0 2px",
          },
          secondary: {
            variant: "buttons.primary",
            color: "background",
            bg: "secondary",
          },
        },
      }}
    >
      {children}
    </ThemeProvider>
  </UserProvider>
);

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

const Inner = () => {
  const { user, signOut, loadingUser, error } = useUser();
  const { showLinks, showGeneralDocs } = useOrg();

  return (
    <>
      {error && (
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {/*
Mentions légales : https://infos.entreprises.blablacardaily.com/mentions-l%C3%A9gales
Politique de confidentialité : https://infos.entreprises.blablacardaily.com/politique-confidentialite
BlaBlaCar Daily Entreprises (change it to BBC Daily): https://entreprises.blablacardaily.com/
          */}
          {error.code === "PERMISSION_DENIED" ? (
            <>
              <Flex mb={2} alignItems="center">
                <Box sx={{ marginTop: "1px" }} pr={2}>
                  <Logo />
                </Box>
                <Box color="darkBackground" fontWeight="bold" fontSize={3}>
                  BlaBlaCar Daily Entreprises
                </Box>
              </Flex>
              <Box p={4} maxWidth="42em">
                <Text as="h2">Bonjour,</Text>
                <Text as="p" mt={2}>
                  Merci pour votre inscription sur BlaBlaCar Daily Entreprises.
                  Votre adresse email n'est pas encore enregistrée dans notre
                  système.
                </Text>
                <Text as="p" mt={2}>
                  Nous vous invitons à vous rapprocher de votre administrateur
                  de la plateforme BlaBlaCar Daily pour que celui-ci nous fasse
                  une demande pour pouvoir vous autoriser l'accès.
                </Text>
                <Text as="p" mt={2}>
                  Si vous ne connaissez pas votre administrateur ou si vous avez
                  besoin d'aide pour vous connecter, nous vous invitons à nous
                  écrire à l'adresse{" "}
                  <Link href="mailto:entreprises@blablacardaily.com">
                    entreprises@blablacardaily.com
                  </Link>
                  .
                </Text>

                <Text as="p" mt={2}>
                  Merci,
                </Text>
                <Text as="p">L'équipe BlaBlaCar Daily</Text>
              </Box>
              <Box mt={5} alignSelf="center">
                <Button
                  variant="outline"
                  onClick={signOut}
                  sx={{ cursor: "pointer" }}
                >
                  Se déconnecter {error.email}
                </Button>
              </Box>
            </>
          ) : (
            "Oops! Something boroke"
          )}
        </Flex>
      )}

      {loadingUser && !error && (
        <Flex justifyContent="center" alignItems="center" height="90vh">
          Loading...
        </Flex>
      )}

      {!loadingUser && !error && (
        <Router>
          <ScrollToTop />
          <Switch>
            <Redirect exact from="/" to="/login" />

            <Route path="/login">
              {user ? <Redirect to="/reports" /> : <Login />}
            </Route>

            <Route path={["/reports", "/documents", "/links"]}>
              {!user && <Redirect to="/login" />}

              <NavBar />

              <Route path="/reports">{user && <Reports />}</Route>
              <Route path="/documents">
                {showGeneralDocs ? user && <Documents /> : <Redirect to="/" />}
              </Route>
              <Route path="/links">
                {showLinks ? user && <OrgLinks /> : <Redirect to="/" />}
              </Route>
            </Route>

            <Route path="*">
              <div>404 not found</div>
            </Route>
          </Switch>
        </Router>
      )}
    </>
  );
};

export const App = () => (
  <ErrorBoundary>
    <Providers>
      <Inner />
      <Box as="footer" bg="darkerBackground" justifyContent="center">
        <Flex
          p={2}
          justifyContent="space-around"
          alignItems="center"
          color="white"
          opacity="0.5"
        >
          <Box fontWeight="bold" mr={2}>
            &copy; BlaBlaCar {new Date().getFullYear()}
          </Box>

          <Link
            color="white"
            href="https://infos.entreprises.blablacardaily.com/mentions-légales"
            target="_blank"
          >
            Mentions Légales
          </Link>
          <Box p={2}>|</Box>
          <Link
            color="white"
            href="https://infos.entreprises.blablacardaily.com/politique-confidentialite"
            target="_blank"
          >
            Politique de Confidentialité{" "}
          </Link>
          <Box p={2}>|</Box>
          <Link
            color="white"
            href="https://entreprises.blablacardaily.com//"
            target="_blank"
          >
            BlaBlaCar Daily Entreprises
          </Link>
        </Flex>
      </Box>
    </Providers>
  </ErrorBoundary>
);
