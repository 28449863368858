import React from "react";
import { Box } from "rebass";

import { Wrap, Wrapper } from "components/Wrap";
import { useUser } from "hooks/useUser";

export const Reports = () => {
  const {
    user: {
      org: { gdsReportId },
    },
  } = useUser();

  if (!gdsReportId) {
    return "No report";
  }

  return (
    <div>
      <Wrapper>
        <Wrap>
          <iframe
            title="GDS Report"
            src={`https://datastudio.google.com/embed/reporting/${gdsReportId}`}
            frameBorder="0"
            style={{ overflow: "hidden", height: "100%", width: "100%" }}
            height="100%"
            width="100%"
          />
          <Box
            // This is to block internal GDS links
            bg="background"
            sx={{
              position: "absolute",
              bottom: 0,
            }}
            width={1}
            height="24px"
          />
        </Wrap>
      </Wrapper>
    </div>
  );
};
