import React from "react";

import { Wrap, Wrapper } from "components/Wrap";

export const Documents = () => (
  <Wrapper>
    <Wrap>
      <iframe
        title="Documents"
        src="https://blablalines-7885827.hs-sites.com/portal-content"
        frameBorder="0"
        style={{ overflow: "hidden", height: "100%", width: "100%" }}
        height="100%"
        width="100%"
      />
    </Wrap>
  </Wrapper>
);
