import React from "react";
import { Box, Flex } from "rebass";

export const Wrapper = (props) => (
  <Flex
    width="100%"
    // minHeight="80vh"
    // height="100%"
    justifyContent="center"
    bg="background"
    {...props}
  />
);

export const Wrap = (props) => (
  <Box
    width="100%"
    minHeight="calc(100vh - 58px - 60px - 50px)"
    sx={{
      position: "relative",
    }}
    {...props}
  />
);

export const Constrain = (props) => <Wrap maxWidth="55em" {...props} />;
