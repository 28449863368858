import React from "react";
import { Box, Flex, Button } from "rebass";
import { NavLink, useRouteMatch } from "react-router-dom";

import { useUser, useOrg } from "hooks/useUser";
import { Logo } from "components/Logo";
import { Constrain } from "components/Wrap";

const LogoBar = () => {
  const { signOut, user } = useUser();
  return (
    <Flex
      width="100%"
      justifyContent="center"
      sx={{
        borderBottom: "1px solid lightGrey",
      }}
    >
      <Constrain width="100%" minHeight="0">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          width={1}
          pb={2}
          pt={3}
          px={3}
        >
          <Flex alignItems="center">
            <Box pr={2}>
              <Logo small />
            </Box>
            {user && (
              <Box ml={3} color="muted">
                {user.org.name}
              </Box>
            )}
          </Flex>
          {user && (
            <Box>
              <Button onClick={signOut} variant="outline">
                Se déconnecter
              </Button>
            </Box>
          )}
        </Flex>
      </Constrain>
    </Flex>
  );
};

const TabLink = ({ to, children }) => {
  const active = !!useRouteMatch(to);
  return (
    <Box
      p={2}
      mx={2}
      as={NavLink}
      sx={{
        color: active ? "secondary" : "muted",
        textDecoration: "none",
        borderBottom: "2px solid",
        borderColor: active ? "secondary" : "muted",
      }}
      to={to}
    >
      {children}
    </Box>
  );
};
const LinkBar = () => {
  const { showLinks, showGeneralDocs } = useOrg();

  return (
    <Flex>
      <Flex width={1} justifyContent="center" alignItems="center">
        <Flex p={2}>
          <TabLink to="/reports">
            <span role="img" aria-label="chart">
              📊
            </span>{" "}
            Rapport
          </TabLink>
          {showGeneralDocs && (
            <TabLink to="/documents">
              <span role="img" aria-label="document">
                📄
              </span>{" "}
              Documents et Informations
            </TabLink>
          )}
          {showLinks && (
            <TabLink to="/links">
              <span role="img" aria-label="document">
                🔗
              </span>{" "}
              Documents personnalisés
            </TabLink>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export const NavBar = () => (
  <Flex flexDirection="column">
    <LogoBar />
    <LinkBar />
  </Flex>
);
